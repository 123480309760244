import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Modal from 'react-modal';
import Model from './Model';
import axios from 'axios';
import api, { getBase } from './../../../api';
import Loader from 'react-loaders';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import $, { param } from 'jquery';
const theme = createMuiTheme();
const customStyles = {
    overlay: {
        zIndex: 1200,
        backgroundColor: 'rgba(0,0,0,0.7)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderColor: 'transparent',
    }
};
class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            current: -1,
            data: '',
            searchvalue: '',
            itemdata: '',
            loading: true,
            category: this.props.category,
            offset: 0,
            total: 0,
            pageNumbers: [],
            open: false,
            UserRoles: false,
            closeModalRename: false,
            modalIsOpenRename: false,
            rename_name: ''
        }
    }
    toggleClass = (index) => {
        this.setState({ current: index === this.state.current ? -1 : index });
    }
    componentDidMount() {
        let initURL = window.location.pathname;
        // console.log('initURL', initURL)
        // console.log('componentDidMount', this.props.Searchdata);
        if (!this.props.Searchdata) {
            if (this.props.currenttab && initURL == '') {
                this.setState({ category: this.props.currenttab }, () => {
                    this.getData();
                });
            }
            else if (initURL != '') {
                this.setState({ category: initURL.replace('/', '') }, () => {
                    this.getData();
                });
            }
        }
        else {
            this.setState({ data: this.props.Searchdata, loading: false })
        }
        // if (this.props.currenttab === '') {
        //     this.props.dispatch({ type: 'CURRENTTAB', currenttab: this.props.category });
        // }
        let Profile = JSON.parse(localStorage.user);
        let UserRole = Profile.profile.fields;
        if (UserRole.role == "supervisor") {
            this.setState({ UserRoles: true })
        }
    }
    getData() {
        this.setState({ loading: true }, () => { this.handleScroll() });
        // let req = '';
        // if (this.props.searchval !== 'search') {
        //     req = '&search_term=' + this.props.searchval
        // }
        let category = this.state.category
        console.log('this.state.category', typeof this.state.category)
        console.log("url>>", this.state.category == "new-collection" || this.state.category == "" ? api.IMAGE_DASHBOARD + '?offset=0&limit=16' : api.IMAGE_DASHBOARD + '?category=' + this.state.category + '&limit=24&offset=' + this.state.offset)
        let urls = this.state.category == "new-collection" || this.state.category == "" ? api.IMAGE_DASHBOARD + '?limit=20&offset=' + this.state.offset : api.IMAGE_DASHBOARD + '?category=' + this.state.category + '&limit=24&offset=' + this.state.offset
        axios.get(getBase(urls), { headers: {} }).then(res => {
            console.log('res images panel', res.data)
            if (res.data.count > 30) {
                for (let i = 1; i <= Math.ceil(res.data.count / 50); i++) {
                    this.state.pageNumbers.push(i);
                }
            }


            if (this.state.category != "new-collection") {
                res.data.data.sort(function (a, b) {
                    if (a.image_name < b.image_name) { return -1; }
                    if (a.image_name > b.image_name) { return 1; }
                    return 0;
                })
            } else {
                res.data.data.sort(function (a, b) {
                    return new Date(b.updated) - new Date(a.updated);
                })
            }

            this.setState({ data: res.data.data, loading: false, total: res.data.count });
        }).catch((err) => {
            this.setState({ loading: false });
        })
    }
    handleUseImage(data) {
        this.setState({ modalIsOpen: true, itemdata: data });
    }
    deleteImage(data) {
        axios.delete(getBase(api.IMAGE_DASHBOARD + '?lgp_id=' + data.data), { headers: {} }).then(res => {
            console.log('data', res.data)
            toast.success("Deleted successfully", {
                position: toast.POSITION.TOP_RIGHT
            });
            let deldata = this.state.data;
            deldata.splice(data.index, 1);
            this.setState({ data: deldata, open: false });
        }).catch((error) => {
            toast.error("Sorry! couldn't Delete Category", {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        data.e.stopPropagation();

    }
    componentWillReceiveProps(nextProps) {
        let initURL = window.location.pathname;
        console.log('initURL', initURL)

        if (nextProps.currenttab != "New Collection" && !nextProps.Searchdata && initURL == '') {
            console.log('componentWillReceiveProps panel', nextProps)
            this.setState({ category: nextProps.currenttab }, () => {
                this.getData();
            });
        }
        else if (initURL != '' && !nextProps.Searchdata) {
            this.setState({ category: initURL.replace('/', '') }, () => {
                this.getData();
            });
        }
        if (nextProps.Searchdata) {
            this.setState({ data: nextProps.Searchdata });
        }
    }
    handleScroll = () => {
        let scrl = $('.image-container ul li .selected').offset();
        $('.image-container').scrollLeft(scrl.left - 100);
    }
    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }
    handleClicks(offset) {
        this.setState({ offset }, () => {
            this.getData();
        });
    }
    deleteimg = (e, data, index) => {
        let newdata = {
            e, data, index
        }
        this.setState({ open: newdata });
        e.stopPropagation();
    }
    renameImage = (e, data, index) => {
        let newdata = {
            e, data, index
        }
        this.setState({ modalIsOpenRename: newdata, rename_name: data.image_name });
        e.stopPropagation();
    }
    closeModalRename = () => {
        this.setState({ modalIsOpenRename: false });
    }
    rename = (data) => {
        const { rename_name } = this.state;
        let Imagedata = data.data;
        // console.log('dd', getBase(api.IMAGE_DASHBOARD + '?lgp_id=' + Imagedata._id + '&image_name=' + rename_name + '.jpeg'))
        axios.put(getBase(api.IMAGE_DASHBOARD + '?lgp_id=' + Imagedata._id + '&image_name=' + rename_name), { headers: { 'Content-Type': 'application/json' } }).then(res => {
            console.log("Rename successfully", res.data.new_image_name)
            let newarr = this.state.data;
            newarr[data.index].image_name = res.data.new_image_name;
            this.setState({ data: newarr, menu: false });

            toast.success("Rename successfully", {
                position: toast.POSITION.TOP_RIGHT
            });
            this.closeModalRename();
        }).catch((error) => {
            toast.error("Sorry! couldn't Rename", {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }
    handledownload = (claim) => {

        fetch(`/getImage?image=${claim.image_url}`)
            .then(response => response.blob())
            .then(imageData => {
                // Download the Blob file.
                const blobUrl = URL.createObjectURL(imageData);

                // Create a temporary anchor element to trigger the download
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = claim.image_name + '.jpeg'; // Set the desired filename with extension
                a.click();

                // Clean up the blob URL
                URL.revokeObjectURL(blobUrl);
            });
    }
    render() {
        let pathval = localStorage.path != undefined ? JSON.parse(localStorage.path) : "";
        const { current, data, itemdata, loading, open, UserRoles, rename_name } = this.state;
        if (loading) {
            return <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Loader type="ball-grid-pulse" color="#000" />
            </div>
        }
        return <div><Grid container spacing={2} style={{ paddingTop: 20 }}>
            {data.length > 0 ? Object.values(data).map((claim, index) => {
                return (
                    <Grid item md={3} key={index}>
                        <div onClick={() => this.toggleClass(index)} style={{ position: 'relative', cursor: 'pointer' }}>
                            <img src={claim.image_url} style={{ width: '100%', borderRadius: '10px' }} />
                            {(current === index) && <div style={{ display: 'flex', alignItems: 'stretch', position: 'absolute', top: 0, left: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', borderRadius: '10px' }}>
                                <div style={{ padding: 15, display: 'flex', width: '100%' }}>
                                    <Grid container>
                                        <Grid item md={12}>
                                            <div onClick={() => this.handleUseImage(claim)} style={{
                                                backgroundColor: 'rgb(255, 255, 255)',
                                                padding: '3px 10px', width: '100%', maxWidth: '120px', borderRadius: '2px', marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}>
                                                <Typography style={{ fontSize: 12, fontWeight: 200, color: '#000' }}>Upload to FC tool</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item md={12} style={{ marginTop: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            <Typography style={{ fontSize: 14, fontWeight: 300, color: '#fff', flex: 13, textAlign: 'left' }}>{claim.image_name.replace('.jpeg', '')}</Typography>
                                            {UserRoles &&
                                                <Tooltip title="Rename" arrow>
                                                    <div style={{ backgroundColor: 'rgb(255, 255, 255)', padding: 4, borderRadius: '2px', textAlign: 'center', marginRight: 8, display: 'flex', alignItems: 'center', }}>
                                                        <BorderColorIcon onClick={(e) => this.renameImage(e, claim, index)} style={{ fontSize: 16, fontWeight: 200, color: '#6c6c6c' }} />
                                                    </div>
                                                </Tooltip>}
                                            {UserRoles &&
                                                <Tooltip title="Delete" arrow>
                                                    <div style={{ backgroundColor: 'rgb(255, 255, 255)', padding: 4, borderRadius: '2px', textAlign: 'center', marginRight: 8, display: 'flex', alignItems: 'center', }}>
                                                        <DeleteIcon onClick={(e) => this.deleteimg(e, claim._id, index)} style={{ fontSize: 16, fontWeight: 200, color: '#6c6c6c' }} />
                                                    </div>
                                                </Tooltip>}
                                            <Tooltip title="Download" arrow>
                                                <div style={{ backgroundColor: 'rgb(255, 255, 255)', padding: 4, borderRadius: '2px', textAlign: 'center', display: 'flex', alignItems: 'center', }}>
                                                    <button
                                                        onClick={() => this.handledownload(claim)}
                                                        // href={claim.image_url}
                                                        style={{ display: 'flex', alignItems: 'center', border: 'none' }} title='Download' download><ArrowDownwardIcon style={{ fontSize: 16, fontWeight: 200, color: '#6c6c6c' }} /></button>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>}
                        </div>
                    </Grid>
                )
            }) : <Grid item md={12}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginTop: 50
                }}>
                <img src="./Images/no-data.png" width='200' />
                <Typography variant="h6" style={{ margin: '20px 0 15px', color: '#000' }}>Sorry! We don't have that in our stock.</Typography>
                <Typography style={{ color: '#000', fontSize: 13, fontWeight: 500 }}>Modify your search keyword or contact your designer</Typography>
            </Grid>}
            <Modal
                isOpen={this.state.modalIsOpenRename}
                onAfterOpen={this.afterOpenModalRename}
                onRequestClose={this.closeModalRename}
                style={customStyles}
            >

                <div style={{ backgroundColor: '#fff', padding: 5, width: 300 }}>
                    <div >
                        <Typography variant="h6">Rename Image </Typography>
                        <TextField
                            id="outlined-full-width"
                            className="nounderline"
                            style={{ width: '100%', border: '1px solid darkgrey', height: 33, borderRadius: '5px', display: 'flex', position: 'relative', marginTop: 15, paddingLeft: 8, paddingRight: 8 }}
                            fullWidth
                            multiline
                            rows="1"
                            value={rename_name.replace(".jpeg", "")}
                            onChange={(e) => this.setState({ rename_name: e.target.value })}
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 26, justifyContent: 'right' }}>
                            <div style={{ marginRight: 15, marginLeft: 'auto' }}>
                                <Button onClick={this.closeModalRename} variant='outlined' style={{ fontWeight: 400, maxWidth: 99, width: '100%', height: 39, textTransform: 'capitalize' }}>Cancel</Button>
                            </div>
                            <div>
                                <Button onClick={() => this.rename(this.state.modalIsOpenRename)} variant='outlined' style={{ fontWeight: 400, maxWidth: 99, width: '100%', height: 39, color: '#fff', backgroundColor: '#000', textTransform: 'capitalize' }}>Rename</Button>
                            </div>
                        </div>
                    </div>

                </div>

            </Modal>
            <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={customStyles}
            >
                <form>
                    <Model {...this.props} data={itemdata} onRequestClose={this.closeModal} />
                </form>
            </Modal>

        </Grid>
            {this.state.total >= 50 && this.state.category != "new-collection" && this.state.category != "" && <Grid container style={{ marginTop: 20 }}>
                <Grid item md={12}>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline />
                        <Pagination
                            limit={50}
                            offset={this.state.offset}
                            total={this.state.total}
                            onClick={(e, offset) => this.handleClicks(offset)}
                        />
                    </MuiThemeProvider>
                </Grid>
            </Grid>}

            <Dialog onClose={() => this.setState({ open: false })} open={open !== false} >
                <DialogTitle>
                    <Typography variant='h6'>Are you sure you want to delete this Image?</Typography>
                </DialogTitle>
                <DialogActions>
                    <Button style={{ boxShadow: 'none' }} onClick={(e) => this.deleteImage(this.state.open)} color="secondary">Delete</Button>
                    <Button color="primary" onClick={() => this.setState({ open: false, menu: false })}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>

    }
}

const mapStateToProps = state => {
    return {
        tabsdata: state.tabsdata,
        imgdata: state.imgdata,
        searchval: state.searchval,
        currenttab: state.currenttab,
        randomnumber: state.randomnumber
    }
}

export default connect(mapStateToProps)(Panel);

Panel.propTypes = {
    classes: PropTypes.object.isRequired,
};

