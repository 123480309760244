import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LOGICALLYIMG from '../icon/Logicallyphotos.png';
import { Zoom } from 'react-slideshow-image';
import axios from 'axios';
import api, { getLogin } from '../../api';
import Loader from 'react-loaders';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        }
    }
    componentDidMount(){
        window.history.pushState('', '', window.origin)
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { username, password } = this.state;
        console.log('onSubmit', username, password)
        axios.post(getLogin(api.LOGIN), { username, password }).then(res => {
            axios.get(getLogin(api.USER), { headers: { 'Authorization': 'Token ' + res.data.token } }).then(res2 => {
                axios.put(getLogin(api.USER + '/status'), { status: "available" }, { headers: { 'Authorization': 'Token ' + res.data.token } });
                axios.get(getLogin(api.PROFILE), { headers: { 'Authorization': 'Token ' + res.data.token } }).then(res3 => {
                    res2.data.data.profile = res3.data.data;
                    console.log('res2.data.data.profile', res2.data.data.profile)
                    this.props.done(res2.data, res.data.token);
                }).catch(err => {
                    console.log(err);
                    this.props.done(res2.data, res.data.token);
                })
            })
        }).catch(error => {
            console.log(error);
        })
    }
    render() {
        const zoomOutProperties = {
            duration: 1500,
            transitionDuration: 3000,
            scale: 1,
            indicators: false,
            arrows: false,
            onChange: (oldIndex, newIndex) => {
                console.log(`slide transition from ${oldIndex} to ${newIndex}`);
            }
        }
        const { username, password } = this.state;
        return (
            <div>
                <Paper>
                    <Grid container >
                        <Grid xs={9} style={{ height: '100vh' }}>
                            <div className="slide-container">
                                <Zoom {...zoomOutProperties}>
                                    <div className="each-slide" style={{display:'flex',alignItems:'stretch',width:'100%',height: '100vh'}}>
                                        <div style={{background:`url(${require('../../image/200.jpg')}) no-repeat`, backgroundSize:'cover',width:'100%'}}>
                                         
                                        </div>
                                    </div>
                                    <div className="each-slide" style={{display:'flex',alignItems:'stretch',width:'100%',height: '100vh'}}>
                                        <div style={{background:`url(${require('../../image/201.jpg')}) no-repeat`, backgroundSize:'cover',width:'100%'}}>
                                          
                                        </div>
                                    </div>
                                    <div className="each-slide" style={{display:'flex',alignItems:'stretch',width:'100%',height: '100vh'}}>
                                        <div style={{background:`url(${require('../../image/202.jpg')}) no-repeat`, backgroundSize:'cover',width:'100%'}}>
                                         
                                        </div>
                                    </div>
                                    <div className="each-slide" style={{display:'flex',alignItems:'stretch',width:'100%',height: '100vh'}}>
                                        <div style={{background:`url(${require('../../image/203.jpg')}) no-repeat`, backgroundSize:'cover',width:'100%'}}>
                                           
                                        </div>
                                    </div>
                                    <div className="each-slide" style={{display:'flex',alignItems:'stretch',width:'100%',height: '100vh'}}>
                                        <div style={{background:`url(${require('../../image/204.jpg')}) no-repeat`, backgroundSize:'cover',width:'100%'}}>
                                           
                                        </div>
                                    </div>
                                    <div className="each-slide" style={{display:'flex',alignItems:'stretch',width:'100%',height: '100vh'}}>
                                        <div style={{background:`url(${require('../../image/205.jpg')}) no-repeat`, backgroundSize:'cover',width:'100%'}}>
                                           
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </Grid>
                        <Grid xs={3} style={{ backgroundColor: '#f8f8f8', height: '100vh', }}>
                            <div style={{ justifyContent: 'center', marginTop: 100, display: 'flex', alignItems: 'center', flexDirection: 'column' }} >
                                <img src={LOGICALLYIMG} style={{ height: 55, paddingRight: 15, marginTop: 3 }} />
                                {/* <h3 style={{ textAlign: 'center' }}>WELCOME</h3> */}
                            </div>

                            <form onSubmit={this.handleSubmit}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                                    <div>
                                        <TextField type='text'
                                            style={{ width: 300 }}
                                            id="username"
                                            label="Username"
                                            variant="outlined"
                                            value={username}
                                            onChange={(e) => this.setState({ username: e.target.value })} />
                                    </div>
                                    <div style={{ marginTop: 25 }}>
                                        <TextField type='password'
                                            style={{ width: 300 }}
                                            id="pwd"
                                            label="Password"
                                            variant="outlined"
                                            value={password}
                                            onChange={(e) => this.setState({ password: e.target.value })} />
                                    </div>
                                    <div style={{ marginTop: 25 }}>
                                        <Button type='submit' style={{ width: 300, height: 55, fontSize: 20, textTransform: 'capitalize', boxShadow: 'none' }} variant="contained" color="primary">Sign in
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }
}

export default Login;