import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import LOGICALLYIMG from './icon/Logicallyphotos.png';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'react-modal';
import Model from './Model/Modal'
import InputAdornment from '@material-ui/core/InputAdornment';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import api, { getBase } from './../api';
import { toast } from 'react-toastify';

const styles = {
    root: {
        flexGrow: 1,

    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    line: {
        border: 'none',
        background: '#ddd',
        height: '1px'

    },
    textField: {
        fontSize: 14,
        width: '100%',
        //backgroundColor: '#fff',
        borderRadius: '4px',
        paddingLeft: 0,
    },
    button: {
        textTransform: 'none',
        width: '100%',
        fontSize: 12,
        backgroundColor : '#f62459'
    },
    Logoutbutton:
    {
        textTransform: 'none',
        width: '100%',
        fontSize: 12,
        backgroundColor : "#050e2b"},
    input: {
        '&::placeholder': {
            padding: 0,
            marginTop: -5
        },
    },
};
const customStyles = {
    overlay: {
        zindex: 999999,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    content: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        width: '65rem',
        maxWidth: 'calc(100vw - 2rem)',
        maxHeight: 'calc(100vh - 2rem)',
        overflowY: 'auto',
        position: 'relative',
        top: 'inherit',
        left: 'inherit'
    }
};
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            modalIsOpen: false,
            textShow: true,
            searchVal: '',
            count: 0,
            UserRoles: false
        }
    }
    openModal = () => {
        this.setState({ modalIsOpen: true, textShow: true });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    componentDidMount = () => {
        axios.get(getBase(api.IMAGE_DASHBOARD), { headers: {} }).then(res => {
            console.log("total res", res.data.count)
            this.setState({ count: res.data.count })
        })
        let Profile = JSON.parse(localStorage.user);
        let UserRole = Profile.profile.fields;
        if (UserRole.role == "supervisor") {
            this.setState({ UserRoles: true })
        }
    }
    handleChange = (e) => {
        console.log("aa", e.target.value)
        if (e.key == 'Enter') {
            let val = e.target.value;
            this.setState({ searchVal: val });
            this.getData(val)
        }
        else if (e.target.value == '') {
            let val = e.target.value;
            this.setState({ searchVal: val });
            this.getData(val)
        }
    }
    handleChange2 = (e) => {
        let val = e.target.value;
        this.setState({ searchVal: val });
    }
    getData(val) {
        this.setState({ loading: true });
        console.log("url", getBase(api.IMAGE_DASHBOARD + '?search_term=' + val))
        if (val == '') {
            this.props.hadlesearch([], val);
        }
        else {
            axios.get(getBase(api.IMAGE_DASHBOARD + '?search_term=' + val), { headers: {} }).then(res => {
                console.log('res images Search', res.data.data)
                this.props.hadlesearch(res.data.data, val);
            }).catch((err) => {
                this.setState({ loading: false });
            })
        }
    }
    handleClose = () => {
        this.setState({ searchVal: '' }, () => this.getData(this.state.searchVal));
    }
    render() {
        const { classes } = this.props;
        const { textShow, searchVal, count, UserRoles } = this.state;
        return (
            <div>
                <AppBar position="static" style={{ backgroundColor: '#fff', boxShadow: 'none' }}>
                    <Toolbar>
                        <Grid container style={{ margin: '20px 0', }}>
                            <Grid item md={2} >
                                <a href="/"><img src={LOGICALLYIMG} style={{ height: 45, paddingRight: 15, marginTop: 3 }} /></a>
                            </Grid>
                            <Grid item md={10} style={{ display: 'flex' }} >
                                <div className="nounderline" style={{ width: '100%', display: 'flex', borderRadius: '5px', position: 'relative', backgroundColor: '#e8e8e8', height: 45, marginRight: 20 }} >
                                    <TextField
                                        id="standard-search"
                                        value={this.state.searchVal}
                                        //onBlur={this.handleBlur}
                                        onChange={this.handleChange2}
                                        onKeyUp={this.handleChange}
                                        placeholder={`Search from our ${count} image collections`}
                                        InputProps={{
                                            startAdornment: (<IconButton><SearchIcon /></IconButton>),
                                            classes: { input: classes.input },
                                            style: { height: 45, paddingLeft: 0, paddingRight: 10 },
                                            endAdornment: (<IconButton onClick={this.handleClose}><CloseIcon /></IconButton>)
                                        }}
                                        className={classes.textField}

                                    />
                                </div>
                                {UserRoles && <Button onClick={this.openModal} variant="contained" color="#f62459" style={{ height: 45, color: '#fff', fontSize: 14, fontWeight: 400, boxShadow: 'none', maxWidth: 155 }} className={classes.button}>
                                    Submit a photo</Button>}
                                <Button onClick={() => this.props.logout()} variant="contained"  style={{ height: 45, color: '#fff', fontSize: 14, fontWeight: 400, boxShadow: 'none', maxWidth: 90, left: 10 }} className={classes.Logoutbutton}>
                                    Logout</Button>
                            </Grid>
                        </Grid>
                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={this.closeModal}
                            style={customStyles}>
                            <Grid container alignItems='center' style={{ marginBottom: 10 }}>
                                <Grid item xs={9} md={9} >
                                    {textShow && <Typography variant="h6" style={{ fontWeight: 400, color: 'white' }}>Upload New Image</Typography>}
                                </Grid>
                                <Grid xs={3} item md={3} style={{ textAlign: 'right' }}>
                                    <Button onClick={this.closeModal}><CloseIcon style={{ color: '#fff', fontSize: 30 }} /></Button>
                                </Grid>

                            </Grid>
                            <form>
                                <Model classes={classes} handleHideTitle={() => this.setState({ textShow: false })} />
                            </form>
                        </Modal>
                    </Toolbar>

                </AppBar>

            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
    return {
        searchval: state.searchval
    }
}
export default connect(mapStateToProps)(withStyles(styles)(Header));
