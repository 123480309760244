import React, { Component } from 'react';
import Header from './Components/Header';
import Login from './Components/Login/index';
import Dashboard from './Dashboard';
import Search from './Dashboard/Components/Search/Search';
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import api, { getLogin } from './api';
import Loader from 'react-loaders';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'qs';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
export default class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      search: [],
      searchVal: '',
      loggedIn: false,
      loading: true,
      error: false,
      user: false
    }
  }
  componentDidMount() {
    console.log('localStorage.token', localStorage.token)
    let urlprops = {
      path: qs.parse(window.location.search, { ignoreQueryPrefix: true }).path,
    }
    //console.log('localStorage.claim_verified_ids',urlprops)
    if (urlprops.path !== undefined) {
      localStorage.path = JSON.stringify(urlprops);
      let baseUrl = window.location.href.split("?")[0];
      window.history.pushState('name', '', baseUrl);
    }


    if (localStorage.token) {
      axios.get(getLogin(api.USER), { headers: { 'Authorization': 'Token ' + localStorage.token } }).then(res2 => {
        axios.get(getLogin(api.PROFILE), { headers: { 'Authorization': 'Token ' + localStorage.token } }).then(res => {
          axios.put(getLogin(api.USER + '/status'), { status: "available" }, { headers: { 'Authorization': 'Token ' + localStorage.token } });
          window.onbeforeunload = () => {
            axios.put(getLogin(api.USER + '/status'), { status: "offline" }, { headers: { 'Authorization': 'Token ' + localStorage.token } });
          }
          res2.data.data.profile = res.data.data;
          this.setState({ user: res2.data.data })
          localStorage.user = JSON.stringify(res2.data.data);
        }).catch(err => {
          console.log(err);
          this.setState({ user: res2.data.data })
          localStorage.user = JSON.stringify(res2.data.data);
        })
        this.setState({ loading: false, loggedIn: true })
      }).catch(error => {
        this.setState({ loading: false })
      })
    } else {
      this.setState({ loading: false })
    }
  }
  handleSubmit = (user, token) => {
    localStorage.token = token;
    localStorage.user = JSON.stringify(user.data);
    this.setState({ loggedIn: true, loading: false, error: false, user: user.data })
  }
  logout = () => {
    axios.put(getLogin(api.USER + '/status'), { status: "offline" }, { headers: { 'Authorization': 'Token ' + localStorage.token } });
    axios.get(getLogin(api.LOGOUT), { headers: { 'Authorization': 'Token ' + localStorage.token } })
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.setState({ loading: false, loggedIn: false });
    window.location.href =  window.origin;
  }
  render() {
    const { loading, loggedIn, error, user } = this.state;
    return (
      <Grid container>
        <Router>
          {/* {(!loggedIn && !loading) && <Grid item md={12}>
          <Login done={this.handleSubmit} error={error} />
        </Grid>}
        {loading && <Loader type="ball-grid-pulse" color="#000" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50% -50%)' }} />}
        {loggedIn && <Grid item md={12}>
          <ToastContainer />
          <Header logout={this.logout} hadlesearch={(data, val) => { this.setState({ search: data, searchVal: val }); console.log('data>>', data, val) }} />
          {this.state.searchVal.length != '' ? <Search Searchdata={this.state.search} val={this.state.searchVal} /> : <Dashboard />}
        </Grid>} */}
          {(!loggedIn && !loading) && <Grid item md={12}>
            <Login done={this.handleSubmit} error={error} />
          </Grid>}
          {loading && <Loader type="ball-grid-pulse" color="#000" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50% -50%)' }} />}
          {loggedIn && <Grid item md={12}>
            <ToastContainer />
            <Route
              path='/'
              render={(props) => <Header logout={this.logout} hadlesearch={(data, val) => { this.setState({ search: data, searchVal: val }); console.log('data>>', data, val) }} />}
            />
            {this.state.searchVal.length != '' ? <Search Searchdata={this.state.search} val={this.state.searchVal} /> : <Dashboard />}
          </Grid>}
        </Router>
      </Grid>
    )
  }
}
