import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import ThumbUpTwoToneIcon from '@material-ui/icons/ThumbUpTwoTone';
import axios from 'axios';
import api, { getBase, getLogin } from './../../api';
import { toast } from 'react-toastify';
import Loader from 'react-loaders';
import Resizer from 'react-image-file-resizer';
const styles = {
    card: {
        maxWidth: 340
    },
    button: {
        textTransform: 'none',
        width: '100%',
        fontSize: 12,
        backgroundColor: '#f62459'
    },
    chip: {
        borderRadius: 4,
        backgroundColor: '#f62459',
        color: '#fff',
        fontSize: 12,
        margin: 4,
        '&:hover, &:focus': {
            backgroundColor: '#f62459',
        },
    }
};
class Modal extends Component {
    state = {
        value: "",
        error: null,
        isKeywords: false,
        modalIsOpen: false,
        showPopup: false,
        showmodel: true,
        categories: '',
        category: '',
        error: '',
        image_name: '',
        alltags: [],
        imageurl: '',
        image: false,
        loading: false,
        imageurl_2: '',
        duplicateImage: false
    }
    handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.value.trim();

            if (value && this.isValid(value)) {
                this.setState({
                    alltags: [...this.state.alltags, this.state.value],
                    value: ""
                });
            }
        }
    };

    handleChange = evt => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleDelete = item => {
        this.setState({
            alltags: this.state.alltags.filter(i => i !== item)
        });
    };
    isValid(tags) {
        let error = null;

        if (this.isInList(tags)) {
            error = `${tags} has already been added.`;
        }

        if (error) {
            this.setState({ error });

            return false;
        }

        return true;
    }

    isInList(tags) {
        return this.state.alltags.includes(tags);
    }

    componentDidMount() {
        this.setState({ categories: JSON.parse(this.props.tabsdata) })
    }
    handlePublish = () => {
        const { category, alltags, imageurl, image_name, imageurl_2 } = this.state;
        this.props.handleHideTitle();
        if (!imageurl) {
            this.setState({ error: 'Please upload an image' })
        }
        else if (image_name == '') {
            this.setState({ error: 'Please enter image name' })
        } else if (category == '') {
            this.setState({ error: 'Please select category' })
        }
        else {
            const dataToCheck = {
                image_name:image_name
            }
            this.setState({ loading: true })
            axios.post(getLogin(api.CHECK_IMAGE), dataToCheck, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + localStorage.token } }).then(res => {
                if (res.data.data.Exists) {
                    this.setState({duplicateImage: true, loading: false}, ()=> {
                        setTimeout(()=> {
                            this.setState({duplicateImage: false})
                        }, 5000)
                    })
                } else {
                    let data = {
                        image: imageurl,
                        image2: imageurl_2,
                        keywords: JSON.stringify(alltags),
                        category,
                        image_name: image_name
                    }
                    axios.post(getBase(api.IMAGE_DASHBOARD), data, { headers: { 'Content-Type': 'application/json' } }).then(res => {
                        if (this.props.currenttab == category) {
                            this.props.dispatch({ type: 'RANDOMNUMBER', randomnumber: Math.random(1000) })
                        }
                        this.setState({ showmodel: false, loading: false, duplicateImage: false });
                    }).catch((error) => {
                        toast.error("Couldn't save image", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        console.error('oops, something went wrong!', error);
                        this.setState({ loading: false })
                    })
                }
                
            }).catch(e => {

            })
            
        }
    }
    fileChangedHandler(event) {
        let img = new Image();
        var _URL = window.URL || window.webkitURL;
        var file = event.target.files[0];
        img.src = _URL.createObjectURL(file);
        var imgwidth = 0;
        var imgheight = 0;
        var that = this;
        var fileInput = false
        if (file) {
            fileInput = true
        }

        if (fileInput) {
            img.onload = function () {
                imgwidth = this.width;
                imgheight = this.height;
                if (imgheight < 600 || imgwidth < 800) {
                    that.setState({ error: 'Image size should be 800 * 600 Pixel.' });
                    return;
                } else {
                    Resizer.imageFileResizer(
                        file,
                        800,
                        600,
                        'JPEG',
                        100,
                        0,
                        uri => {
                            console.log('uri', uri)
                            that.setState({
                                error: '',
                                imageurl: uri,
                                image: true,
                                isKeywords: true
                            })
                        },
                        'base64'
                    );
                    Resizer.imageFileResizer(
                        file,
                        800,
                        600,
                        'JPEG',
                        70,
                        0,
                        uri => {
                            console.log('uri', uri)
                            that.setState({
                                error: '',
                                imageurl_2: uri,
                                // image: true,
                                // isKeywords: true
                            })
                        },
                        'base64'
                    );
                }
            }

        }
        else {
            toast.error("Image could not be uploaded, check to see if it is the correct format", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }
    handleClose = () => {
        this.setState({
            imageurl: '',
            image: false,
            alltags: [],
            image_name: '',
            category: '',
            showmodel: true,
        })
    }
    render() {
        const { classes } = this.props;
        const { isKeywords, showmodel, categories, error, tags, alltags, image_name, image, loading, duplicateImage } = this.state;
        if (loading) {
            return <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Loader type="ball-grid-pulse" color="#000" />
            </div>
        }
        return (
            <div style={{ backgroundColor: '#fff', padding: 40 }}>
                {showmodel ?
                    <Grid container spacing={6} alignItems="stretch">
                        <Grid item md={6}>
                            {!image && <div
                                style={{ position: 'relative', padding: '30px', border: '1.5px dashed #ced4da', height: 350, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <input type="file" accept="image/*" style={{ width: '100%', height: 400, opacity: 0, position: 'absolute', top: 0, left: 0, cursor: 'pointer' }} name="name"
                                    onChange={(e) => { this.fileChangedHandler(e); this.setState({ image: false }); }}
                                />
                                <img src="/Images/imageupload.png" width="100" />
                                <Typography style={{ textAlign: 'center', marginTop: 40 }}>Browse image</Typography>
                            </div>}

                            {image && <img src={this.state.imageurl} style={{ width: '100%', maxWidth: '100%', maxHeight: 500 }} />}
                        </Grid>
                        <Grid item md={6} style={{ display: 'flex', alignItems: 'center' }}>
                            <Grid container style={{ flexDirection: 'column' }}>
                                <div style={{ color: 'red', fontSize: 13, marginBottom: 10 }} id="errall">{error}</div>
                                {!isKeywords && <Grid item>
                                    <Typography style={{ fontSize: 13 }}>1. Size should be 800 x 600 Pixel.<br />2. Photos are clear and Original.<br />3. Respect the intellectual property of others.<br />4. please check image name. </Typography>
                                </Grid>}
                                <Grid item style={{ marginTop: 5 }}>
                                    <TextField
                                        id="outlined-disabled"
                                        label="Image Name"
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        value={image_name}
                                        onChange={(e) => this.setState({ image_name: e.target.value })}
                                    />
                                </Grid>
                                <Grid item style={{ marginTop: 15 }}>
                                    <NativeSelect
                                        className="nounderline"
                                        style={{ width: '100%', border: '1px solid #ced4da', borderRadius: '5px', padding: '8px 15px' }}
                                        onChange={(e) => this.setState({ category: e.target.value, error: '' })}>
                                        <option value="" disabled selected hidden>Select Category</option>
                                        {Object.values(categories).map((item, index) => item.category == "New Collection" ? '' : <option value={item.category}>{item.category}</option>)}
                                    </NativeSelect>
                                </Grid>
                                {isKeywords && <Grid item style={{ marginTop: 10 }}>
                                    <TextField
                                        id="outlined-disabled"
                                        label="Type Keywords"
                                        value={this.state.value}
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        onKeyDown={this.handleKeyDown}
                                        onChange={this.handleChange}
                                    />
                                    <div style={{ marginTop: 5 }}>
                                        {alltags.map(item => {
                                            return <Chip
                                                onClick={() => this.handleClick}
                                                label={item}
                                                key={item}
                                                size="small"
                                                clickable
                                                className={classes.chip}
                                                onDelete={() => this.handleDelete(item)}
                                                deleteIcon={<CloseIcon style={{
                                                    color: '#fff',
                                                    fontSize: 12,
                                                }} />}
                                            />
                                        })}
                                    </div>
                                </Grid>}
                                {duplicateImage && <Grid item style={{ marginTop: 15 }}>
                                    <Typography style={{color: '#f62459'}}>
                                        <b>Note: </b>Image name exist use a different name
                                    </Typography>
                                    </Grid>}
                                <Grid item style={{ marginTop: 15 }}>
                                    <Button onClick={this.handlePublish} variant="contained" style={{ height: 50, color: '#fff', boxShadow: 'none', marginTop: 10, fontWeight: 300, fontSize: 14 }} className={classes.button}>
                                        Publish to Logically photos</Button>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    : <Popup handleClose={this.handleClose} />}
            </div>

        )
    }
}

class Popup extends Component {
    render() {
        return (
            <Grid container spacing={4} direction="column" justify="center" alignItems="center">
                <Grid item>
                    <img src="/Images/sucess.png" width="100" />
                </Grid>
                <Grid item style={{ display: 'flex' }}>
                    <div style={{ flex: 12, marginRight: 25 }}>
                        <Typography style={{ fontSize: 46 }}>Thanks for submitting</Typography>
                    </div>
                    <div style={{ flex: 1, marginTop: 10 }}>
                        <ThumbUpTwoToneIcon style={{ fontSize: 50 }} />
                    </div>
                </Grid>
                <Grid item >
                    <Button variant="contained"
                        onClick={() => this.props.handleClose()}
                        style={{ height: 50, color: '#fff', backgroundColor: '#f62459', boxShadow: 'none', marginTop: 10, fontWeight: 300, fontSize: 14 }}>
                        Add More Images</Button>
                </Grid>
            </Grid>

        );
    }
}

const mapStateToProps = state => {
    return {
        tabsdata: state.tabsdata,
        currenttab: state.currenttab,
        randomnumber: state.randomnumber
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Modal));