import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import FolderSection from './../FolderSection/FolderSection';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Modal from 'react-modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Model from './Model';
import axios from 'axios';
import api, { getBase } from './../../../api';
import Loader from 'react-loaders';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import TextField from '@material-ui/core/TextField';
import { Switch, Route, NavLink, BrowserRouter as Router } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Panel from './Panel';
const theme = createMuiTheme();

class ButtonSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            active: true,
            imagedata: ''
        }
    }
    componentDidMount() {
        axios.get(getBase(api.CATEGORIES), { headers: { 'Content-Type': 'application/json' } }).then(res => {
            let data = res.data;
            data.unshift({ category: "New Collection" });
            this.props.dispatch({ type: 'TABS_DATA', tabsdata: JSON.stringify(data) });
            console.log('comman', data)
            this.setState({ data })
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.tabsdata !== this.props.tabsdata) {
            let data = JSON.parse(nextProps.tabsdata);
            this.setState({ data })
        }
    }
    toggleClass = () => {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    }
    render() {
        const { classes, Searchdata } = this.props;
        const { active, data, newdata } = this.state;
        return (
            <div style={{ position: 'relative' }}>
                {active ? <Tabs selected={this.props.currenttab} activeindex={1} classes={classes} {...this.props}>
                    {Object.values(data).map((claim, index) => {
                        return <Panel title={claim.category} category={claim.category} {...this.props} key={index}></Panel>
                    })}
                </Tabs> : <div>
                        <FolderSection {...this.props} handleActive={(val) => this.setState({ active: val })} active={active} checking={data} />
                    </div>}
                <Button onClick={this.toggleClass} style={{ position: 'absolute', right: 24, top: 0, maxWidth: 155, width: '100%', height: 45 }}>
                    {active ? <Typography style={{ color: '#f62459', textTransform: 'none', fontSize: 14 }}>View all groups</Typography> : <Typography style={{ color: '#f62459', textTransform: 'none', fontSize: 14 }}>Close View</Typography>}</Button>
                <div style={{ position: 'relative',marginTop : -50 }}>
                    <LinearProgress color="red"/>
                </div>
            </div>
        );
    }
}
class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: window.location.pathname.replace('/', '') || '',
            activeindex: this.props.activeindex || 0
        }
    }
    scroll = (direction) => {
        let far = $('.image-container').width() / 2 * direction;
        let pos = $('.image-container').scrollLeft() + far;
        $('.image-container').animate({ scrollLeft: pos }, 1000)
    }
    handleChange = (index, title) => {
        this.setState({ selected: title, activeindex: index });
        this.props.dispatch({ type: 'CURRENTTAB', currenttab: title });
    }
    render() {
        const { classes, children } = this.props;
        let activedata;
        if (children.props != undefined) {
            activedata = children[this.state.activeindex]
            console.log("children", activedata.props)
        }
        return (
            <div>
                <Router>
                    <Paper className={classes.paper1} >
                        <Grid container>
                            <Grid item md={10}>
                                <div className="main">
                                    <a className="prev" onClick={this.scroll.bind(null, -1)}><ArrowBackIosIcon style={{ fontSize: 20 }} /></a>
                                    <div className="image-container">
                                        <ul className="inline" >
                                            {children.map((elem, index) => {
                                                let style = '';
                                                if (this.state.selected == '') {
                                                    style = (elem.props.title === 'New Collection') ? 'selected' : ''
                                                }
                                                return <li key={index} onClick={this.handleChange.bind(this, index, elem.props.title)}>
                                                    <NavLink className={style} to={`/${elem.props.title.replace(/ +/g, '-').toLowerCase()}`} activeClassName='selected'>{elem.props.title}</NavLink></li>
                                            })}
                                        </ul>
                                    </div>
                                    <a className="next" onClick={this.scroll.bind(null, 1)}><ArrowForwardIosIcon style={{ fontSize: 20 }} /></a>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Switch>
                        <Route path={`/${this.props.currenttab.replace(/ +/g, '-').toLowerCase()}`} render={(props) =>
                            <div className="tab">{this.props.children[this.state.activeindex]}</div>} /></Switch>
                </Router>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        tabsdata: state.tabsdata,
        imgdata: state.imgdata,
        searchval: state.searchval,
        currenttab: state.currenttab,
        randomnumber: state.randomnumber
    }
}

export default connect(mapStateToProps)(ButtonSection);

ButtonSection.propTypes = {
    classes: PropTypes.object.isRequired,
};


