import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import api, { getBase } from './../../../api';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class Model extends Component {
    constructor(props) {
        super(props);
        this.state = {
            source_name: '',
            data: this.props.checking,
        }
    }

    handleCreate(e){
        e.preventDefault();
        const { source_name, data } = this.state;
        let ndata = data;
        const CheckVal = data.find((item, index) => {
            return item.category.toLowerCase() == source_name.toLowerCase();
        })
        if (CheckVal == undefined) {
            ndata.push({ "category": source_name });
            console.log('ndata', ndata)
            axios.post(getBase(api.CATEGORIES), { "category": source_name }, { headers: { 'Content-Type': 'application/json' } }).then(res => {
                console.log('res category', res.data.data)
                toast.success("Saved changes successfully", {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.props.dispatch({ type: "TABS_DATA", tabsdata: JSON.stringify(ndata) });
                this.props.onRequestClose();
            }).catch((error) => {
                toast.error("Couldn't save category", {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.error('oops, something went wrong!', error);
            })
        } else {
            if (CheckVal.category.toLowerCase() == source_name.toLowerCase()) {
                toast.error("Already Exist", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }

    }
    render() {
        const { classes, onRequestClose } = this.props;
        const { source_name } = this.state;
        return (
            <div style={{ backgroundColor: '#fff', padding: 5 }}>
                <form onSubmit={(event)=>this.handleCreate(event)}>
                <div >
                    <Typography variant="h6">Create New Category</Typography>
                    <TextField
                        id="outlined-full-width"
                        className="nounderline"
                        style={{ width: '100%', border: '1px solid darkgrey', height: 33, borderRadius: '5px', display: 'flex', position: 'relative', marginTop: 15, paddingLeft: 8, paddingRight: 8 }}
                        fullWidth
                        value={source_name}
                        onChange={(e) => this.setState({ source_name: e.target.value })}
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 26, justifyContent: 'right' }}>
                        <div style={{ marginRight: 15, marginLeft: 'auto' }}>
                            <Button onClick={onRequestClose} variant='outlined' style={{ fontWeight: 400, maxWidth: 99, width: '100%', height: 39, textTransform: 'capitalize' }}>Cancel</Button>
                        </div>
                        <div>
                            <Button type='submit' variant='outlined' 
                            style={{ fontWeight: 400, maxWidth: 99, width: '100%', height: 39, color: '#fff', backgroundColor: '#000', textTransform: 'capitalize' }}>Create</Button>
                        </div>
                    </div>
                </div>
</form>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        tabsdata: state.tabsdata
    }
}

export default connect(mapStateToProps)(Model);