const ApiConstants = {
    BASE_URL: 'https://thelogicallydata.co.uk/',
    BASE_LOGIN : 'https://checker.logically.co.uk/',
    CATEGORIES: 'lgp/category',
    IMAGE_DASHBOARD: 'lgp/images',
    LOGIN: 'account/login',
    USER: 'account/user',
    USERS: 'account/users',
    PROFILE: 'account/users/profile',
    LOGOUT: 'account/logout',
    CHECK_IMAGE: 'api/check_image'
}

export function getBase(endpoint) {
    return ApiConstants.BASE_URL + endpoint;
}
export function getLogin(endpoint) {
    return ApiConstants.BASE_LOGIN + endpoint;
}
export default ApiConstants;