import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AppsIcon from '@material-ui/icons/Apps';
import Modal from 'react-modal';
import TextField from '@material-ui/core/TextField';
import ListFolder from './ListFolder';
import Model from './Model';

const styles = theme => ({
    textField: {
        position: 'relative',
        width: '100%',
        color: '#ffffff'
    },
    largeIcon: {
        fontSize: "2em"
    }
});
const customStyles = {
    overlay: {
        zIndex: 1200,
        backgroundColor: 'rgba(0,0,0,0.7)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 400,
        width: '100%'
    }
};

class FolderSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checking: this.props.checking,
            active: false,
            UserRoles : false
        }
    }
    componentDidMount() {
        let Profile = JSON.parse(localStorage.user);
        let UserRole = Profile.profile.fields;
        if (UserRole.role == "supervisor") {
            this.setState({ UserRoles: true })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.checking !== this.props.checking) {
            this.setState({ checking: nextProps.checking })
            console.log('componentWillReceiveProps 1>>', this.state.checking);
        }
    }
    toggleClass = () => {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    }
    handleGotoPage() {
        this.props.handleActive(true);
    }
    render() {
        const { classes } = this.props;
        const { active, checking,UserRoles } = this.state;
        return (
            <div>
                <Paper className={classes.paper1} >
                    <Grid container >
                        <Grid item md={7} style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <AppsIcon className={classes.largeIcon} style={{ color: '#00b6b1', marginRight: 18 }} />
                            <FormatListBulletedIcon className={classes.largeIcon} style={{ color: '#00b6b1' }} /> */}
                        </Grid>
                        <Grid item md={5} style={{ paddingRight: 165,height: 45 , textAlign: 'right' }}>
                            {UserRoles &&<Button onClick={this.toggleClass} style={{ height: 45 }}>
                                <Typography className={classes.largeIcon} style={{ color: '#f62459', textTransform: 'none', fontSize: 14 }}>Add category</Typography></Button>}
                        </Grid>
                    </Grid>
                </Paper>
                <div style={{ padding: '20px 15px' }}>
                    {active && <CreateFolder classes={classes} checking={checking} {...this.props} />}
                    <ListFolder checking={checking} UserRoles={UserRoles}  {...this.props} goToPage={this.handleGotoPage.bind(this)} />
                </div>
            </div>
        );
    }
}


class CreateFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false
        }
    }
    openModal = () => {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal = () => {
        //this.subtitle.style.color = '#fff';
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }
    render() {
        const { classes, checking } = this.props;
        return (
            <div style={{ display: 'flex', marginBottom: '30px' }}>
                <div style={{ flexBasis: "18%", margin: '1%' }} >
                    <Paper onClick={this.openModal} style={{ display: 'flex', padding: '10px 20px', borderRadius: 5, alignItems: 'center', cursor: 'pointer' }} >
                        <div><img src='/Images/folder.png' style={{ height: 34, paddingRight: 15, marginTop: 3, flex: 1 }} /></div>
                        <div><Typography className={classes.largeIcon} style={{ textTransform: 'none', fontSize: 14, flex: 1, fontWeight: 600 }}>Create Category</Typography></div>
                    </Paper>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    classes={classes}>
                    <form>
                        <Model onRequestClose={this.closeModal} checking={checking} {...this.props} />
                    </form>
                </Modal>
            </div>
        );
    }
}





export default withStyles(styles)(FolderSection);

FolderSection.propTypes = {
    classes: PropTypes.object.isRequired,
};


