import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createStore } from 'redux';
import { Provider } from 'react-redux';


const initialState = {
  tabsdata:'',
  imgdata : '',
  searchval:'',
  currenttab:'',
  randomnumber:0
};
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#f8f8f8',
      main: '#2d2d2d',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      error: '#E52329'
    },
    secondary: {
      light: '#7CC78F',
      main: '#00B6B1',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
      error: '#E52329'
    },
  },
  typography: {
    useNextVariants: true,
  }
});
const App2 = () => (
  <MuiThemeProvider theme={theme}>
    <Provider store = {store}>
      <App/>
    </Provider>
  </MuiThemeProvider>
  )
function reducer(state = initialState, action) {
  console.log('reducer', state, action);

  switch (action.type) {
    case 'TABS_DATA':
      return {
        ...state,
        tabsdata: action.tabsdata,
      
      }
      case 'IMAGEDATA':
      return {
        ...state,
        imgdata:action.imgdata,
       
      }
      case 'SEARCHVAL':
      return {
        ...state,
        searchval:action.searchval
       
      }
      case 'CURRENTTAB':
      return {
        ...state,
        currenttab:action.currenttab
       
      }
      case 'RANDOMNUMBER':
        return {
          ...state,
          randomnumber:action.randomnumber
         
        }
    default:
      return state;
  }
}
const store = createStore(reducer);
ReactDOM.render(<App2/>, document.getElementById('root'));
