import React, { Component } from 'react';
import { connect } from 'react-redux';
import ButtonSection from './Components/ButtonSection/ButtonSection';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
const styles = theme => ({
    root: {
        width: '100%',
    },
    button: {
        textTransform: 'none',
        width: '32px',
        fontSize: 12,
        borderRadius: '50%',
        minWidth: '32px',
        padding: 0,
        boxShadow: 'none'
    },
    Typography: {
        marginBottom: 20
    },
    badge: {
        zIndex: 'auto',
        color: '#fff'
    },
    sortByMain: {
        display: 'inline-block'
    },
    sortBy: {
        color: '#b7b2b2',
        fontWeight: 'bold',
        paddingTop: 6,
        fontSize: 13
    },
    capheading: {
        color: 'black',
        position: 'relative',
        left: `${1}rem`,
        // top: `${0.2}rem`,
    },
    expandicon: {
        position: 'relative',
        top: `${0.3}rem`,
        height: 18,
    },
    mb10: {
        marginBottom: 10,
        // overflow: 'hidden', 
        flexWrap: 'wrap',
        height: '34px'
    },
    paper1: {
        padding: theme.spacing.unit * 0,
        color: theme.palette.text.secondary,
        padding: '0 24px',
        borderRadius: 0,
        boxShadow: '0px 15px 12px -1px rgba(0,0,0,0.05)'

    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00B6B1'
        },
        secondary: {
            main: '#0b040c'
        },
    },
    typography: {
        useNextVariants: true,
    }
});

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div>
                <ButtonSection {...this.props} />
            </div>
        );
    }
}

export default withStyles(styles)(Dashboard);
