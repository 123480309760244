
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

export default class Model extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    sendImagetoDashboard(image) {
        if (localStorage.path !== undefined || localStorage.path !== '') {
            let urlprops = JSON.parse(localStorage.path)
            if (urlprops.path !== undefined) {
                let data = {
                    imageurl: image.image_url,
                    path: urlprops.path
                }

                //console.log('sendImagetoDashboard', image)
                localStorage.path= JSON.stringify('');
                //localStorage.b64image = 'data:image/jpeg;base64,' + image.image_base64
                 window.location.href = data.path+'?image_url='+image._id
            }
        }
    }

    render() {
        const { classes, onRequestClose, data } = this.props;
        // console.log('image_url',data)
        return (
            <div style={{ backgroundColor: '#fff', padding: 5 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 30 }}>
                        <img src={data.image_url} style={{ width: '180px', height: '230px', borderRadius: '10px' }} />
                    </div>
                    <div>
                        <Typography style={{ fontWeight: 600, fontSize: 19, marginBottom: 7 }} >Are you sure want to use this image?</Typography >
                        <Typography style={{ fontSize: 15 }} >{data.image_name}</Typography >
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 26 }}>
                            <div style={{ marginRight: 30 }}>
                                <Button onClick={onRequestClose} variant='outlined' style={{ maxWidth: 99, width: '100%', height: 39 }}>Cancel</Button>
                            </div>
                            <div>
                                <Button onClick={() => this.sendImagetoDashboard(data)} variant='outlined' style={{ maxWidth: 99, width: '100%', height: 39, color: '#fff', backgroundColor: '#000' }}>Upload</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
