import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ButtonSection from './../ButtonSection/ButtonSection'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import api, { getBase } from './../../../api';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Switch, Route, NavLink,BrowserRouter as Router } from "react-router-dom";

const customStyles = {
    overlay: {
        zIndex: 1200,
        backgroundColor: 'rgba(0,0,0,0.7)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 400,
        width: '100%'
    }
};

class ListFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checking: this.props.checking,
            menu: false,
            current: -1,
            modalIsOpen: false,
            modelindex: false,
            open: false,
            categorydata: '',
            tabsdata: []
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.checking !== this.props.checking) {
            this.setState({ checking: nextProps.checking })
            console.log('componentWillReceiveProps 2>>', this.state.checking);
        }
    }
    componentDidMount() {
        console.log(this.props.checking);
    }
    menu(e, index) {
        e.stopPropagation();
        this.setState({ menu: !this.state.menu, current: index });
    }
    delete = (data) => {
        axios.delete(getBase(api.CATEGORIES + '?category=' + data.data), { headers: {} }).then(res => {
            toast.success("Deleted successfully", {
                position: toast.POSITION.TOP_RIGHT
            });
            let deldata = this.state.checking;
            deldata.splice(data.index, 1);
            this.setState({ checking: deldata, menu: false, open: false });
        }).catch((error) => {
            toast.error("Sorry! couldn't Delete Category", {
                position: toast.POSITION.TOP_RIGHT
            });
        })
        data.e.stopPropagation();
    }
    rename = (data) => {

        const { rename_name, checking } = this.state
        let dataImage = {
            "old_category": data.data,
            "new_category": rename_name
        }
        const CheckVal = checking.find((item, index) => {
            return item.category.toLowerCase() == rename_name.toLowerCase();
        })
        if (CheckVal == undefined) {
            axios.put(getBase(api.CATEGORIES), dataImage, { headers: { 'Content-Type': 'application/json' } }).then(res => {
                console.log("Rename successfully", res.data)
                toast.success("Rename successfully", {
                    position: toast.POSITION.TOP_RIGHT
                });
                let newarr = this.state.checking;
                newarr[data.index].category = res.data.new_category;
                this.setState({ checking: newarr, menu: false });
                this.closeModal();
            }).catch((error) => {
                toast.error("Sorry! couldn't Rename Category", {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
        } else {
            if (CheckVal.category.toLowerCase() == rename_name.toLowerCase()) {
                toast.error("Already Exist", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }

    }
    closeModal = () => {
        this.setState({ modalIsOpen: false,menu:false });
    }
    openModal(e, data, index) {
        let newdata = {
            e, data, index
        }
        this.setState({ modalIsOpen: newdata, modelindex: true, rename_name: data });
        e.stopPropagation();
    }
    handleClick(e, category,index) {
         this.props.dispatch({ type: 'CURRENTTAB', currenttab: category });
        setTimeout(() => {
            this.props.goToPage();
        }, 100)
    }
    deleteCat = (e, data, index) => {
        let newdata = {
            e, data, index
        }
        this.setState({ open: newdata });
        e.stopPropagation();
    }
    render() {
        const { classes, UserRoles } = this.props;
        const { checking, menu, current, modelindex, data, rename_name, open } = this.state;
        // checking.sort(function (a, b) {
        //     if (a.category < b.category) { return -1; }
        //     if (a.category > b.category) { return 1; }
        //     return 0;
        // })
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', position: 'relative' }}>
                {Object.values(checking).map((claim, index) => {
                    if (claim.category != "New Collection") {
                        return (
                            <div key={index} style={{ margin: '1%', flexBasis: "18%" }}>
                                <NavLink style={{textDecoration : 'none'}} 
                                    to={`/${claim.category.replace(/ +/g, '-').toLowerCase()}`} 
                                    activeClassName='selected'
                                    onClick={(e) => this.handleClick(e,claim.category,index)}>
                                    <Paper  style={{ position: 'relative', display: 'flex', padding: '10px 20px', borderRadius: 5, cursor: 'pointer', alignItems: 'center' }} >
                                    <div ><img src='/Images/folder.png' style={{ height: 34, paddingRight: 15, marginTop: 3, flex: 1 }} /></div>
                                        <div ><Typography 
                                        style={{ textTransform: 'none', fontSize: 14, flex: 1, fontWeight: 600,color:'#2d2d2d' }}> {claim.category}</Typography></div>
                                        <div style={{ marginLeft: 'auto', color: '#9e9595', height: 24 }}>
                                            {UserRoles && <MoreVertIcon 
                                            onClick={(e) => {e.preventDefault();this.menu(e, index)}} />}
                                            {menu && current === index && <Paper style={{ position: 'absolute', right: 0, top: 45, padding: 10, zIndex: 1000 }}>
                                                <div onClick={(e) => {e.preventDefault();this.deleteCat(e, claim.category, index)}} 
                                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                                                    <DeleteIcon style={{ color: '#a99696', fontSize: 20, marginRight: 10 }} />
                                                    <Typography>Delete</Typography>
                                                </div>
                                                <hr style={{ color: '#ddd' }} />
                                                <div onClick={(e) => {e.preventDefault();this.openModal(e, claim.category, index)}} 
                                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                                                    <BorderColorIcon style={{ color: '#a99696', fontSize: 20, marginRight: 10 }} />
                                                    <Typography>Rename</Typography>
                                                </div>
                                            </Paper>}
                                        </div>

                                    </Paper>
                                    </NavLink>
                            </div>
                        )
                    }
                })}
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                >

                    <div style={{ backgroundColor: '#fff', padding: 5 }}>
                        <div >
                            <Typography variant="h6">Rename Category</Typography>
                            <TextField
                                id="outlined-full-width"
                                className="nounderline"
                                style={{ width: '100%', border: '1px solid darkgrey', height: 33, borderRadius: '5px', display: 'flex', position: 'relative', marginTop: 15, paddingLeft: 8, paddingRight: 8 }}
                                fullWidth
                                multiline
                                rows="1"
                                value={rename_name}
                                onChange={(e) => this.setState({ rename_name: e.target.value })}
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 26, justifyContent: 'right' }}>
                                <div style={{ marginRight: 15, marginLeft: 'auto' }}>
                                <Button onClick={() => this.rename(this.state.modalIsOpen)} variant='outlined' style={{ fontWeight: 400, maxWidth: 99, width: '100%', height: 39, color: '#fff', backgroundColor: '#000', textTransform: 'capitalize' }}>Rename</Button>
                                </div>
                                <div>
                                    <Button onClick={this.closeModal} variant='outlined' style={{ fontWeight: 400, maxWidth: 99, width: '100%', height: 39, textTransform: 'capitalize' }}>Cancel</Button>
                                </div>
                            </div>
                        </div>

                    </div>

                </Modal>
                <Dialog onClose={() => this.setState({ open: false })} open={open !== false} >
                    <DialogTitle><Typography variant='h6'>Are you sure you want to delete this category?</Typography>
                        <Typography variant='body2' style={{ color: 'red', marginTop: 10 }}>Note: If you delete this Category, all images present in this category will be deleted.</Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button style={{ boxShadow: 'none' }} onClick={(e) => this.delete(this.state.open)} color="secondary">Delete</Button>
                        <Button color="primary" onClick={() => this.setState({ open: false, menu: false })}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        currenttab: state.currenttab,
        tabsdata: state.tabsdata
    }
}
export default connect(mapStateToProps)(ListFolder);



